import '../sass/project.scss';


import {Calendar} from '@fullcalendar/core';
// import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
// import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

const elem = document.querySelector('.dateinput');
if (elem != undefined) {
    const datepicker = new Datepicker(elem, {
        buttonClass: 'btn',
        language: 'nl',
    });
}

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!


document.addEventListener('DOMContentLoaded', function () {
    let calendarEl = document.getElementById('calendar');

    let calendar = new Calendar(calendarEl, {
        themeSystem: 'bootstrap5',
        plugins: [bootstrap5Plugin, dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin],
        editable: true,
        // selectable: true,
        aspectRatio: 1.8,
        // scrollTime: '00:00', // undo default 6am scrollTime

        headerToolbar: {
            left: 'today prev,next',
            center: 'title',
            right: 'timeGridWeek,dayGridMonth,listWeek',
        },
        buttonIcons: {
            close: ' fas fa-times',
            prev: ' fas fa-chevron-left',
            next: ' fas fa-chevron-right',
            prevYear: ' fas fa-angle-double-left',
            nextYear: ' fas fa-angle-double-right'
        },
        toolbar: {},

        events: "/api/courses/calendar",
        dateClick: function (info) {
            alert('Clicked on: ' + info.dateStr);
        }
    });

    calendar.render();
});
